@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  color: $surrogate-9;
  font-weight: $font-weight-medium;
  cursor: pointer;
  border: none;
  background: none;
  width: fit-content;
  padding: 0;
  line-height: 1.275;

  &.tiny {
    font-size: $font-size-14;
    .icon-right {
      margin-left: 0.375rem;
    }
    .icon-left {
      margin-right: 0.375rem;
    }
  }

  &.small {
    font-size: $font-size-14;

    .icon {
      height: 1.125rem;
      width: 1.125rem;
    }

    .icon-right {
      margin-left: 0.375rem;
    }

    .icon-left {
      margin-right: 0.375rem;
    }

    @include media-from(sm) {
      font-size: $font-size-16;

      .icon {
        height: 1.25rem;
        width: 1.25rem;
      }

      .icon-right {
        margin-left: 0.375rem;
      }

      .icon-left {
        margin-right: 0.375rem;
      }
    }
  }

  &.medium {
    font-size: $font-size-16;

    .icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    .icon-right {
      margin-left: 0.375rem;
    }

    .icon-left {
      margin-right: 0.375rem;
    }

    @include media-from(sm) {
      font-size: $font-size-18;

      .icon {
        height: 1.5rem;
        width: 1.5rem;
      }

      .icon-right {
        margin-left: 0.6rem;
      }

      .icon-left {
        margin-right: 0.6rem;
      }
    }
  }

  &.large {
    font-size: $font-size-18;

    .icon-right {
      margin-left: 0.6rem;
    }

    .icon-left {
      margin-right: 0.6rem;
    }

    @include media-from(sm) {
      font-size: $font-size-20;

      .icon-right {
        margin-left: 0.5rem;
      }

      .icon-left {
        margin-right: 0.5rem;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: $surrogate-4;
  }

  &.left {
    margin-left: 0;
    margin-right: auto;
  }

  &.right {
    margin-right: 0;
    margin-left: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }
}

.default {
  &.transition {
    transition: transform $duration-default;

    &:hover {
      transform: translate3d(0.75rem, 0, 0);
    }
  }
}

.highlighted {
  background-color: $surrogate-2;
  border-radius: $border-radius-small;
  font-weight: $font-weight-medium;
  transition: all $duration-default ease;

  &:hover {
    background-color: $surrogate-9;
    color: $neutral-0;
    box-shadow: $shadow-button-link-hover;
    transform: translateY(-4px);
  }

  &.tiny {
    padding: 0.75rem;
  }

  &.small {
    padding: 0.75rem;

    @include media-from(sm) {
      padding: 1rem;
    }
  }

  &.medium {
    padding: 1rem;

    @include media-from(sm) {
      padding: 1.25rem;
    }
  }

  &.large {
    padding: 1.25rem;

    @include media-from(sm) {
      padding: 1.5rem 2rem;
    }
  }
}

.full {
  width: 100%;
}

.auto {
  width: auto;
}
