@import '../../styles/index';

.gradient {
  display: flex;
  box-shadow: none;

  &.default {
    &::before {
      position: absolute;
      left: 0;
      top: 0;
    }

    &.initial {
      background-color: $neutral-0;
      color: $surrogate-9;

      &::before {
        background-position: center;
        background-repeat: no-repeat;
        border-radius: ($button-height * 0.5);
        content: '\00a0';
        display: block;
        height: 3rem;
        min-width: 3rem;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFF' fill-rule='evenodd'%3E%3Cpath d='m18.286 11.518h9.4567c0.68955 0 1.0836 0.68955 0.68955 1.2806-4.2358 6.3045-8.3732 12.609-12.609 18.815-0.39403 0.68955-1.4776 0.39403-1.4776-0.39403v-10.737h-9.5553c-0.59105 0-0.98508-0.68955-0.68955-1.2806 4.2358-6.3045 8.4717-12.51 12.609-18.815 0.49254-0.68955 1.5761-0.39403 1.5761 0.39403v10.737z' /%3E%3C/g%3E%3C/svg%3E%0A"),
          $gradient-enercity;
        background-size: 50% auto, 100%;
      }
    }

    &.pending {
      border: $neutral-0 solid 0.375rem;
      color: $neutral-0;
      background-image: linear-gradient(
        45deg,
        $violet-9 30%,
        $red-9 36%,
        $red-9 63%,
        $violet-9 69%
      );
    }

    &.fail,
    &.success,
    &.info {
      background-color: $neutral-0;
      padding-right: 0.375rem;
      justify-content: center;

      &::after {
        position: absolute;
        height: 3rem;
        min-width: 3rem;
      }
    }

    &.success {
      &::after {
        background-color: $green-9;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='%23FFF' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 40% auto;
      }
    }

    &.info {
      &::after {
        background-color: $blue-9;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg  fill='%23FFF' stroke='none' stroke-width='1'%3E%3Cpath d='M30.4380476,1.67581047 C28.1952441,-0.558603491 24.5106383,-0.558603491 22.2678348,1.67581047 L0,23.8603491 L0,32 L8.17021277,32 L30.4380476,9.81546135 C32.5206508,7.58104738 32.5206508,3.91022444 30.4380476,1.67581047 Z M6.72841051,28.80798 L3.20400501,28.80798 L3.20400501,25.2967581 L21.787234,6.78304239 L25.3116395,10.2942643 L6.72841051,28.80798 Z M28.0350438,7.58104738 L27.5544431,8.05985037 L24.0300375,4.54862843 L24.5106383,4.06982544 C25.4718398,3.11221945 27.0738423,3.11221945 28.0350438,4.06982544 C29.1564456,5.02743142 29.1564456,6.6234414 28.0350438,7.58104738 Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        background-size: 40% auto;
      }
    }
  }
}

.default {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: $neutral-0;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 ($button-height * 0.5);
  margin: 0.25em auto;
  line-height: 1.25rem;

  @include media-from(sm) {
    padding: 0 ($button-height * 0.5);
    min-width: 20.9375rem;
  }

  &::after {
    background-color: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1% auto;
    border-radius: ($button-height * 0.5);
    content: '\00a0';
    display: block;
    height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: min-width $duration-default ease-in-out,
      background-color $duration-default ease-in-out,
      background-size $duration-default ease-in-out;
    z-index: $index-negative;

    @include media-from(sm) {
      border-radius: ($button-height * 0.5);
    }
  }

  i {
    width: 1.35rem;
    height: 1.35rem;
    contain: strict;
  }

  &.noCenter {
    margin-left: 0;
    margin-right: 0;
  }

  &.initial {
    background-color: $surrogate-9;
  }

  &.pending {
    animation: AnimateGradientPosition 2s linear reverse infinite;
    background-image: linear-gradient(
      45deg,
      $violet-9 30%,
      $surrogate-9 36%,
      $surrogate-9 63%,
      $violet-9 69%
    );
    background-size: 300% 100%;
    transform: none;
    box-shadow: none;

    @include reduced-motion {
      animation: none;
    }

    &::after {
      opacity: 0;
    }
  }

  &.fail,
  &.success,
  &.info {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    z-index: $index-zero;
    pointer-events: none;
    justify-content: flex-end;

    &::after {
      min-width: $button-medium-height;

      @include media-from(sm) {
        min-width: $button-medium-height;
      }
    }
  }

  &.fail {
    &::after {
      background-color: $red-9;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23FFFFFF' d='M14.822 12l8.466 8.466c.79.79.79 2.03 0 2.822s-2.03.79-2.822 0L12 14.822l-8.466 8.466c-.79.79-2.03.79-2.822 0s-.79-2.03 0-2.822L9.178 12 .712 3.534c-.79-.79-.79-2.03 0-2.822s2.03-.79 2.822 0L12 9.178 20.466.712c.79-.79 2.03-.79 2.822 0s.79 2.03 0 2.822L14.822 12z' fill-rule='evenodd' /%3E%3C/svg%3E%0A");
      background-size: 40% auto;
    }
  }

  &.success {
    &::after {
      background-color: $green-9;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 34 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFF' fill-rule='evenodd'%3E%3Cg transform='translate(-540 -1038)' fill-rule='nonzero'%3E%3Cg transform='translate(540 1026)'%3E%3Cpolygon points='29.866 12 12.22 29.37 3.5566 22.772 0 27.32 10.7 35.459 12.721 37 34 16.054'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: 40% auto;
    }
  }

  &.info {
    &::after {
      background-color: $blue-9;
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2254%22%20height=%2254%22%3E%0A%20%20%20%20%3Cpath%20d=%22M42.9%203.648c.514-.514%201.347-.514%201.86%200l5.582%205.583c.514.514.514%201.347%200%201.86L12.234%2049.21c-.126.126-.276.225-.442.29L2.49%2053.222a1.32%201.32%200%200%201-1.71-1.71L4.5%2042.207c.066-.165.165-.316.29-.442L42.9%203.648zM6.843%2043.435l-2.48%206.203%206.203-2.48L47.562%2010.16%2043.84%206.44%206.843%2043.435zm-1.365-.515l5.583%205.582%201.86-1.86L7.34%2041.06l-1.86%201.86zm1.86-1.86l5.583%205.582c1.24%201.24-.62%203.1-1.86%201.86L5.478%2042.92c-1.24-1.24.62-3.1%201.86-1.86zM48.28%207.272l1.86-1.86-1.55-1.55-1.86%201.86%201.55%201.55zm-4.342-2.48l3.722-3.72c.514-.514%201.347-.514%201.86%200l3.41%203.41c.514.514.514%201.347%200%201.86l-3.72%203.722c-.514.514-1.347.514-1.86%200l-3.412-3.412c-.514-.514-.514-1.347%200-1.86zm-6.14%203.312l-.904-.904c-.752-.752-1.99-.745-2.762.028l-5.987%205.986%209.653-5.11zM20.094%2017.54L32.27%205.366c1.796-1.796%204.7-1.813%206.484-.028l2.164%202.164c.626.626.467%201.68-.315%202.093L21.64%2019.634c-1.335.707-2.614-1.025-1.546-2.093z%22%20fill=%22%23FFF%22%20fill-rule=%22nonzero%22/%3E%0A%3C/svg%3E');
      background-size: 40% auto;
    }
  }

  &.pending,
  &.info,
  &.fail,
  &.success {
    &:focus,
    &:active {
      outline: none;
    }
  }

  &:disabled,
  &.inactive {
    background-color: $surrogate-2;
    transform: none;
    color: $surrogate-4;
  }
}

.state-message {
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(30%);

  &-fail {
    color: $red-9;
  }

  &-success {
    color: $green-9;
  }

  &-info {
    color: $blue-9;
  }

  &-initial,
  &-pending {
    opacity: 0;
    transform: translateX(100%);
    overflow: hidden;
    transition: transform $duration-default ease-in-out,
      opacity $duration-default ease-in-out;
    position: absolute;
  }

  &-initial {
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
    }
  }
}

.initial .state-message-initial,
.pending .state-message-pending {
  opacity: 1;
  transform: translateX(0%);
  max-width: 100%;
  position: relative;
}

.initial {
  .state-message-pending {
    transform: translateX(-100%);
  }
}

.fail .state-message-fail,
.success .state-message-success,
.info .state-message-info {
  padding-right: 2.5rem;
  opacity: 1;
  position: relative;
  transform: translateX(0%);
  transition: transform $duration-short ($duration-default * 2) ease-in-out,
    opacity $duration-short ($duration-default * 2) ease-in-out;

  @include media-from(sm) {
    padding-right: 4rem;
  }
}

@keyframes AnimateGradientPosition {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.fail.inactive,
.info.inactive,
.success.inactive {
  background-color: transparent !important;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background: $gradient-enercity;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  z-index: $index-button-modal;

  @include ie-only {
    transform: translate(0, 0) !important;
    padding-top: 0 !important;
  }

  @include media-from(md) {
    @include vertical-media-from(big) {
      overflow-y: hidden;
    }
  }
}

.modal-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $index-button-modal-backdrop;
}

.modal-content-wrapper {
  position: relative;
  z-index: $index-button-modal-wrapper;
  display: flex;
  margin: auto;
  padding: 0 0.5rem;
  max-width: calc(100% - 1rem);

  > * {
    opacity: 0;
    animation: fade $duration-default 1 forwards;
  }

  @include media-from(md) {
    max-width: calc(100% - 2rem);
    padding: 0;
  }
}

.close-icon {
  position: absolute;
  right: 1.8rem;
  top: 1rem;
  border-radius: 50%;
  color: $neutral-0;
  background-color: transparentize($neutral-12, 0.75);
  padding: 0.75rem;
  height: 2.75rem;
  width: 2.75rem;
  transition: transform $duration-default, background-color $duration-default;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    height: 1.3rem;
    width: 1.3rem;
  }

  &:focus,
  &:hover {
    transform: translateY(-2px);
    background-color: transparentize($neutral-12, 0);
    cursor: pointer;
  }

  @include media-from(xl) {
    @include vertical-media-from(big) {
      top: -5rem;
      right: -4rem;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.has-Icon {
  margin-left: 2.5rem;
}

// Button Align

.left {
  margin-left: 0;
  margin-right: auto;
}

.right {
  margin-left: auto;
  margin-right: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.text {
  margin: 0;

  & > p {
    margin: 0;
  }
}

.inactive .state-message-pending {
  color: $neutral-0;
}

.gradient,
.default {
  &.orange {
    background-color: $orange-9;

    &:focus {
      box-shadow: 0 1px 3px 0 rgba($orange-9, 12%),
        0 8px 16px 0 rgba($orange-9, 12%),
        0 0 0 4px rgba($orange-9, 12%);
    }
  }

  &.turquoise {
    background-color: $turquoise-9;

    &:focus {
      box-shadow: 0 1px 3px 0 rgba($turquoise-9, 12%),
        0 8px 16px 0 rgba($turquoise-9, 12%),
        0 0 0 4px rgba($turquoise-9, 12%);
    }
  }

  &.surrogate {
    background-color: $surrogate-9;

    &:focus {
      box-shadow: 0 1px 3px 0 rgba($surrogate-9, 12%),
        0 8px 16px 0 rgba($surrogate-9, 12%),
        0 0 0 4px rgba($surrogate-9, 12%);
    }
  }

  &:disabled,
  &.inactive {
    background-color: $surrogate-2;
    transform: none;
    color: $surrogate-4;

    &:focus {
      box-shadow: 0 1px 3px 0 rgba($surrogate-4, 12%),
        0 8px 16px 0 rgba($surrogate-4, 12%),
        0 0 0 4px rgba($surrogate-4, 12%);
    }
  }
}
