@import '../../styles/index';

.default {
  color: $surrogate-7;
  background-color: $neutral-0;
  font-size: $font-size-14;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  transition: color $duration-default;

  &.invalid {
    color: $red-9;
    background-color: transparent;
  }
}

.on-gradient {
  background-color: transparent;
  color: $neutral-0;
}
