@import '../../styles/index';

.base {
  width: 100%;
}

.slider {
  width: calc(100% + 3rem);
  padding-left: 0.5rem;
  padding-right: 1rem;
  transform: translateX(-0.5rem);
}
