@import '../../styles/index';

.warning-banner {
  align-items: center;
  background-color: $yellow-3;
  border-radius: $border-radius-large;
  color: $neutral-11;
  display: grid;
  font-size: 1rem;
  gap: 1rem;
  grid-template-columns: 2rem auto;
  line-height: 1.3;
  padding: 1rem;

  a {
    color: $neutral-12;
    text-decoration: underline;
  }
}

.warning-icon {
  color: $yellow-9;
}
