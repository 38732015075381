@import '../../styles/index';

.content {
  border-radius: $border-radius-default $border-radius-default;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  max-width: calc(100% - 1rem);
  padding: 2.5rem 1rem;
  pointer-events: all;
  position: relative;
  z-index: $index-modal-conent;

  > div:not(:last-child) {
    @include space-between-containers();
  }

  &.smedium {
    padding: 3rem 1rem;

    @include media-from(sm) {
      padding: 3rem;
    }
  }

  @include media-from(sm) {
    margin-bottom: 3rem;
    margin-top: 6.25rem;
    min-height: unset;
    padding: 3.5rem 2rem;
  }

  @include media-from(xs) {
    border-radius: $border-radius-default;
    max-width: calc(
      100% - 4rem - env(safe-area-inset-left) - env(safe-area-inset-right)
    );
    padding: 3.5rem 2rem;
    @include horizontal-padding-with-safe-area(2rem);
  }

  &.social-medium {
    padding: 3rem 1rem;

    @include media-from(sm) {
      padding: 3rem;
    }
  }
}

.wrapper {
  padding-bottom: 2rem;
  padding-top: 0rem;

  position: relative;

  @include media-from(sm) {
    padding-block: 2rem;
  }
}

.white {
  background-color: $neutral-0;
}

.none {
  background-color: transparent;
  box-shadow: none;
}

.with-space-between-children {
  @include space-between-elements(3rem);
}

.no-margin {
  margin: auto;
}

.no-padding {
  padding: 0;
}
