@import '../../styles/index';

.icon {
  svg {
    transition: color $duration-default;
  }
}

.icon-active {
  svg {
    color: $surrogate-4;
  }
}

.icon-inactive {
  svg {
    color: $surrogate-9;
  }
}
