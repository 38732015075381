@import '../../styles/index';

.link {
  display: flex;
  flex-shrink: 0;
}

.iframe {
  width: 100%;
  height: 100dvh;
  background-color: white;

  @include media-from(sm) {
    height: 80vh;
  }
}

.button {
  all: unset;
}
