@import './variables/colors';
@import './mixins/index';

.enercity-body,
body.sb-show-main {
  background-color: $neutral-2;
  font-family: 'Euclid Enercity', sans-serif;
  line-height: 1.5;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;

  &.background-white {
    background-color: $neutral-0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 10rem);

    & > * {
      width: 100%;
    }
  }
  // for protection I kept the old value (body-scroll-lock) if we use it on the old context
  // @todo: can be deleted when we have everything live
  &.body-scroll-lock,
  &.scroll-lock {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }

  a {
    text-decoration: none;
  }

  div {
    -webkit-overflow-scrolling: touch;
  }
}

body.lock-scroll {
  height: 100vh;
  overflow: hidden;
}

body > div.pac-container.pac-logo.hdpi {
  border-top: 0 !important;
  font-family: 'Euclid Enercity', sans-serif !important;
  margin-left: -16px !important;
  margin-top: 1.5rem !important;
  // its 34px beacuse of 2px border
  max-width: calc(30rem - 34px);
  padding: 0.625rem !important;
  width: calc(100% - 64px) !important;

  @include media-from(xs) {
    max-width: 444px !important;
    width: 100% !important;
  }

  @include media-from(sm) {
    max-width: 404px !important;
  }

  > div.pac-item {
    border: 1px transparent !important;
    border-radius: 3px;
    color: $surrogate-9 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    padding: 0.5rem 0.25rem !important;

    &:hover {
      background-color: rgba(199, 16, 92, 0.1) !important;
    }

    > span.pac-icon.pac-icon-marker {
      display: none !important;
    }

    > span.pac-item-query {
      color: $surrogate-9 !important;
      font-size: 1.125rem !important;
    }
  }

  &:after {
    margin-bottom: -0.5rem !important;
  }
}

[data-color='yellow'] {
  color: $yellow-11;
}
[data-color='green'] {
  color: $green-9;
}
[data-color='turquoise'] {
  color: $turquoise-9;
}
[data-color='blue'] {
  color: $blue-9;
}
[data-color='orange'] {
  color: $orange-11;
}
[data-color='purple'] {
  color: $violet-9;
}

.visually-hidden {
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  white-space: nowrap !important;

  &:not(caption) {
    position: absolute !important;
  }
}
