@import '../../../../styles/index';

.form {
  max-width: 20rem;
  width: 100%;
}

.link {
  color: $surrogate-9;
  text-align: center;
  text-decoration: underline;
}
