@import '../../styles/index';

.base {
  width: 100%;
  border-radius: $border-radius-default;
  padding: 1rem 1rem 1rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.12);
  z-index: $index-guided-box;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  box-shadow: none;
  overflow-y: scroll;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @include media-from(md) {
    padding: 4.5rem 3.5rem;
    min-height: 25rem;
  }
}

.mobile-full {
  padding-top: 3.75rem;

  @include media-from(md) {
    padding-top: 4.5rem;
  }
}

.container {
  display: flex;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  flex-direction: column;

  @include media-from(md) {
    flex-direction: row;
  }
}

.white {
  color: $neutral-12;
  background-color: $neutral-0;

  .link {
    color: $surrogate-9;
  }

  .close-full {
    color: $neutral-0 !important;
    background-color: $surrogate-9;
  }

  .teaser a {
    color: $surrogate-9;
  }
}

.coloured {
  color: $neutral-0;
  background-color: $surrogate-9;

  .link {
    color: $neutral-0;
  }

  .close-full {
    color: $surrogate-9 !important;
    background-color: $neutral-0;
  }

  .teaser a {
    color: $neutral-0;
    text-decoration: underline;
  }
}

.title {
  font-weight: $font-weight-bold;
  width: 100%;
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.2;

  @include media-from(md) {
    font-size: 3.5rem;
    max-width: 50%;
    margin: 0 5rem 0 0;
  }
}

.right-side {
  width: 100%;
  position: relative;

  @include media-from(md) {
    max-width: 50%;
    max-height: 100%;
  }
}

.right-side-full {
  position: relative;
}

.full-text-wrapper {
  width: 100%;
  position: relative;

  @include media-from(md) {
    height: 100%;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}

.teaser {
  overflow-y: hidden;
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 0.75rem 0;

  @include media-from(sm) {
    font-size: 1.25rem;
    padding-right: 1rem;
    margin: unset;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  font-weight: $font-weight-medium;
  font-size: 1.375rem;
  transition: padding-left $duration-default;
  padding: 0 0 0.25rem 0;

  @include media-from(sm) {
    padding: 0;
  }
  &:hover,
  &:focus {
    outline: none;
    padding-left: 0.75rem;
  }

  > i {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 1rem;
  }
}

.close-full {
  background: transparent;
  border: none;
  height: 3rem;
  width: 3rem;
  top: 1rem;
  right: 1rem;
  position: fixed;
  transition: transform;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  & i {
    width: 1.5rem;
    height: 1.5rem;
  }

  @include media-from(md) {
    top: 3rem;
    right: 3rem;
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
