@import '../../styles/index';

.content {
  width: 100%;
  max-width: 1440px;
  max-height: 100dvh;
  position: relative;
  transition: transform $duration-long;
  display: flex;
  object-fit: contain;

  iframe {
    border: none;
  }

  @include media-from(lg) {
    width: 80%;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
}

.base {
  opacity: 0;
  pointer-events: none;
  transition: opacity $duration-default;
  height: 100dvh;
  width: 100vw;
  z-index: $index-light-box;
  display: flex;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  align-items: center;
}

.visible {
  opacity: 1;
  pointer-events: all;
}

.close-icon {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  color: $surrogate-9;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $neutral-0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  z-index: 20;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 8px 32px 0 rgba(0, 0, 0, 0.16);

  position: absolute;
  right: 1rem;
  top: 1rem;

  @include media-from(lg) {
    right: 2rem;
  }
}
