@import '../../styles/index';

.base {
  font-size: $font-size-18;
  font-weight: $font-weight-light;
  line-height: 1.6;

  > * {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > p {
    margin-bottom: 1rem;
  }

  @include media-from(sm) {
    font-size: $font-size-20;
  }

  a {
    color: $surrogate-9;
    cursor: pointer;
    position: relative;
    top: 0;
    transform: scale3d(0, 0, 0);
    transition: text-decoration $duration-short ease-in-out;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  strong,
  b {
    font-weight: $font-weight-bold;
  }

  ul {
    @extend %list-checkmark;
  }
}

.default {
  color: $neutral-12;
}

.default-white,
[data-layout-background='gradient'] .default.auto-color,
[data-bg-color='gradient'] .default.auto-color {
  color: $neutral-0;

  a {
    color: $neutral-0;
  }

  // checkmark color
  ul li::before {
    background-color: $neutral-0;
  }
}

.medium {
  color: $neutral-12;
  font-size: $font-size-18;
  font-weight: $font-weight-regular;
  line-height: 1.75rem;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 1.875rem;
  }
}

.small {
  font-size: $font-size-18;

  ul {
    li {
      font-weight: $font-weight-medium;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
      padding-left: 2rem;

      @include media-from(sm) {
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
        padding-left: 2rem;
      }

      &::before {
        height: 1.125rem;
        margin-left: -2rem;
        margin-top: -2px;
        width: 1.125rem;

        @include media-from(sm) {
          height: 1.125rem;
          margin-left: -2rem;
          width: 1.125rem;
        }
      }
    }
  }
}

.list-regular {
  ul {
    li {
      font-weight: $font-weight-regular;
    }
  }
}

.footnote-base {
  &,
  p {
    color: $neutral-11;
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
    line-height: 1.75;
    margin: 0;
  }

  a {
    color: $surrogate-9;
  }
}

.highlightedBase {
  border-left: 3px solid $surrogate-9;
  p {
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
    line-height: 1.6;
    margin-block-end: 0;
    margin-block-start: 0;
    padding-left: 1rem;
    padding-top: 0;

    @include media-from(sm) {
      font-size: $font-size-20;
      padding-left: 1.5rem;
    }
  }
}

.auto {
  text-align: unset;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
