@import '../../styles/index';

.base {
  color: $red-9;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  padding: 0 $space-between-fields;
  animation: AnimateHorizontalBounce 0.4s ease-in-out;

  @include reduced-motion {
    animation: none;
  }

  @include media-from(sm) {
    position: absolute;

    &.relativePosition {
      position: relative;
    }
  }
}

.whiteColor {
  color: $neutral-0;
}
