@import '../../styles/index';

.default {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: background-color $duration-default, transform $duration-default,
    box-shadow $duration-default, color $duration-default;
  user-select: none;

  @include media-from(xsbetween) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @include media-from(xs) {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  &:not(.no-hover) {
    &:focus,
    &:hover {
      transform: translateY(-4px);
    }
  }

  &:disabled,
  &.inactive {
    background-color: $surrogate-2;
    box-shadow: none;
    transform: none;
    color: $surrogate-4;

    &:hover {
      box-shadow: none;
    }
  }

  &:focus {
    outline: none;
    box-shadow: $shadow-button-focus;
  }
}

.icon {
  margin-right: 0.5rem;

  & + .text {
    margin-left: 0.5rem;
  }
}

// Button Size

.small {
  height: $button-small-height;
  border-radius: $button-small-height * 0.5;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;

  .icon {
    width: 1.125rem !important;
    height: 1.125rem !important;
  }

  .text {
    margin: 0;
    // workaround for old enercity.de styles - delete when fully relaunched
    font-family: 'Euclid Medium', 'Euclid Enercity', sans-serif;
  }
}

.medium {
  height: $button-medium-height;
  border-radius: $button-medium-height * 0.5;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;

  @include media-from(xs) {
    font-size: $font-size-18;
    line-height: initial;
  }

  .icon {
    width: 1.375rem !important;
    height: 1.375rem !important;
  }
}

.large {
  height: $button-height;
  border-radius: $button-height * 0.5;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;

  @include media-from(xs) {
    font-size: $font-size-20;
    line-height: initial;
  }

  .icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

// Button Color

.secondary {
  background-color: $neutral-0;
  color: $surrogate-9;
  box-shadow: $shadow-button;

  &:focus,
  &:hover {
    box-shadow: $shadow-button-hover;
  }
}

.tertiary {
  background-color: transparent;
}

.outline {
  border: 2px solid $surrogate-9;
  color: $surrogate-9;
  background: none;
}

// Button Width

.full {
  width: 100%;
}

.auto {
  width: auto;

  &.small {
    min-width: auto;
  }

  &.medium {
    min-width: 230px;
  }
}

// Button Align

.left {
  margin-left: 0;
  margin-right: auto;
}

.right {
  margin-left: auto;
  margin-right: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.text {
  margin: 0;

  & > p {
    margin: 0;
  }
}

// special link styles

.link-wrapper {
  display: flex;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.auto {
    width: auto;
  }

  &.full {
    width: 100%;
  }
}

.link.auto {
  width: auto;
  display: inline-flex;
}

.addon.small {
  padding-left: $button-small-height;

  .addon-container {
    height: $button-small-height;
    width: $button-small-height;
    right: calc(100% - #{$button-small-height});
  }
}

.addon.medium {
  padding-left: $button-medium-height;

  .addon-container {
    height: $button-medium-height;
    width: $button-medium-height;
    right: calc(100% - #{$button-medium-height});
  }
}

.addon.large {
  padding-left: $button-height;
}

.addon .addon-container {
  height: $button-height;
  width: $button-height;
  right: calc(100% - #{$button-height});
}

.addon.outline .addon-wrapper {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
}

.addon-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  padding: 0.375rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
}

.primary {
  background-color: $surrogate-9;
  color: $neutral-0;

  .addon-wrapper {
    background-color: $neutral-0;
    border: 0.375rem solid $surrogate-9;
    color: $surrogate-9;
  }
}

.secondary,
.outline,
.tertiary {
  .addon-wrapper {
    border: 0.375rem solid $neutral-0;
    color: $neutral-0;

    &.plain {
      background: $surrogate-9;
    }

    &.gradient {
      background: $gradient-enercity;
    }

    &.gradient-mein-h-gas {
      background: $gradient-enercity-netz;
    }
  }
}

.addon-container {
  display: inline-block;
  position: absolute;
  align-self: flex-start;
  top: 0;
}

// Work arround for Privacy ... @cbartz
.privacy {
  margin-top: 3rem;
}
