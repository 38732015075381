@import '../../styles/index';

.wrapper {
  position: relative;
}

.container {
  position: relative;
  display: flex;
  border-radius: $form-field-border-radius;
  border: 2px solid;

  &.icon-outside {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .prefix-box,
  .suffix-box {
    display: none;
    align-items: center;
    justify-content: center;
    border-style: solid;
    padding: 0 1rem;
  }

  .suffix-box {
    border-width: 0 0 0 2px;

    @include media-from(xsbetween) {
      display: flex;
    }
  }

  .prefix-box {
    border-width: 0 2px 0 0;

    @include media-from(xs) {
      display: flex;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M22 18v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1m1 4h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1m-9-4v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1m1 4h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1m-9-4v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1m1 4h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1M4.22 4H7v1a1 1 0 102 0V4h14v1a1 1 0 102 0V4h2.78C29.01 4 30 4.99 30 6.22V10H2V6.22C2 4.99 2.99 4 4.22 4m23.56-2H25V1a1 1 0 10-2 0v1H9V1a1 1 0 10-2 0v1H4.22A4.22 4.22 0 000 6.22v21.56A4.22 4.22 0 004.22 32h23.56A4.22 4.22 0 0032 27.78V6.22A4.22 4.22 0 0027.78 2' fill='%23c7125c' fill-rule='evenodd'/%3E%3C/svg%3E");
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }

    // fix additional space in safari
    &::-webkit-datetime-edit,
    &::-webkit-datetime-edit-fields-wrapper {
      display: block;
    }

    &::-webkit-datetime-edit,
    &::-webkit-datetime-edit-fields-wrapper,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      padding: 0px;
    }
    // end of fix
  }
}

.full {
  width: 100%;
}

.half {
  @include media-from(sm) {
    width: 50% !important;
  }
}

.two-fifths {
  @include media-from(sm) {
    width: 40% !important;
  }
}

.label {
  display: block;
  font-size: $font-size-18;
  font-weight: $font-weight-regular;
  line-height: 1rem;
  position: absolute;
  top: 0;
  transform-origin: left bottom;
  transform: scale(0.6) translate(0, 0.5rem);
  transition: transform $duration-default linear,
    color $duration-default ease-in-out;
  user-select: none;
  white-space: nowrap;

  &.placeholder {
    font-weight: $font-weight-regular;
    transform: scale(1) translate(0, calc(-50% + 1.75rem));
    color: $surrogate-7;
  }
}

.label-suffix {
  display: none;

  @include media-from(sm) {
    display: inline;
  }
}

.label-suffix-component {
  display: flex;
}

.icon {
  display: flex;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: $surrogate-9;

  &.outside {
    position: initial;
    margin-left: 1rem;

    @include media-from(xs) {
      position: absolute;
      right: -2.5rem;
      top: 1.2rem;
    }
  }
}

.icon-highlighted {
  width: 3.75rem;
  height: 3.75rem;
  background-color: rgba($neutral-0, 0.3);
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
}

.form-field {
  background-color: $neutral-0;
  box-sizing: border-box;
  cursor: text;
  display: block;
  min-height: 3.5rem;
  padding: 0.5rem 1rem;
  position: relative;
  transition: border-color $duration-default ease-in-out;
  width: 100%;

  font-size: 1.125rem;

  & textarea {
    min-height: 6.25rem;
    resize: vertical;
  }

  & input,
  & textarea {
    background: none;
    border: none;
    box-shadow: none;
    display: block;
    line-height: 1.6;
    margin: 0.75rem 0 0;
    min-width: 100%;
    outline: none;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset !important;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.no-label {
    display: flex;

    & input {
      margin: 0;
    }
  }

  &.focus {
    &.label {
      color: $surrogate-9;
    }
  }

  &.disabled {
    background-color: $surrogate-2;
  }

  &.with-icon {
    & input,
    & textarea {
      padding-right: 3rem;
    }
  }

  &.with-suffix-box {
    border-radius: $form-field-border-radius 0 0 $form-field-border-radius;
  }
}

.form-field-textarea {
  padding-bottom: 1rem;
}

.form-field.inverted {
  background-color: rgba($neutral-0, 0.4);
}

@mixin form-field-color-theme(
  $base-color,
  $color-05,
  $color-20,
  $color-60,
  $shadow
) {
  &.container {
    border-color: $color-20;

    &.focus {
      border-color: $base-color;
      box-shadow: $shadow;
    }
  }
  .prefix-box,
  .suffix-box {
    background-color: $color-05;
    color: $base-color;
    border-color: $color-20;

    &.focus {
      color: $base-color;
      border-color: $base-color;
      background-color: $color-05;
    }
  }

  .form-field {
    & input,
    & textarea {
      color: $base-color;
    }
  }

  .icon {
    color: $base-color;
  }

  .label {
    color: $base-color;
  }

  .placeholder {
    color: $color-60;
  }
}

.surrogate {
  @include form-field-color-theme(
    $surrogate-9,
    $surrogate-2,
    $surrogate-4,
    $surrogate-7,
    $shadow-form-field-surrogate-active
  );
}

.turquoise {
  @include form-field-color-theme(
    $turquoise-9,
    $turquoise-2,
    $turquoise-4,
    $turquoise-7,
    $shadow-form-field-turquoise-active
  );
}

.orange {
  @include form-field-color-theme(
    $orange-9,
    $orange-2,
    $orange-4,
    $orange-7,
    $shadow-form-field-orange-active
  );
}

.surrogate,
.turquoise,
.orange {
  &.container {
    &.invalid {
      border-color: $red-9;
      &.focus {
        box-shadow: none;
      }

      .suffix-box,
      .prefix-box {
        background-color: rgba($red-9, 0.05);
        border-color: $red-9;
        color: $red-9;
      }
    }
  }
  .form-field {
    &.invalid {
      background-color: rgba($red-9, 0.05);

      &,
      & .label,
      & input,
      & textarea {
        border-color: $red-9;
        color: $red-9;
      }
    }

    &.with-gradient {
      background-color: rgba($neutral-0, 0.4);
      border-radius: $form-field-border-radius;
      border: 2px solid transparent;

      & input,
      & textarea {
        color: $neutral-0;
      }

      &.focus {
        border-color: $neutral-0;
      }

      &.invalid {
        background-color: rgba($neutral-0, 0.4);

        &,
        & .label,
        & input,
        & textarea {
          border-color: $neutral-0;
          color: $neutral-0;
        }
      }
    }
  }

  .icon {
    &.with-gradient {
      color: $neutral-0;
    }
  }

  &.container {
    &.with-gradient {
      border: none;
      &.focus {
        border: none;
      }
    }
  }

  .label {
    &.with-gradient {
      color: $neutral-0;
      opacity: 0.8;
    }
  }
}

.with-suffix-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4rem);
}
