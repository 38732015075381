@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  width: 100%; // maybe this should be a fullWidth prop? But for now it's fine

  @include responsive-properties(
    gap flex-direction justify-content align-items
  );
}

.is-wrapping {
  flex-wrap: wrap;
}

.reset {
  --align-items-initial: initial;
  --flex-direction-initial: column;
  --justify-content-initial: initial;
}
