@import '../../../styles/index';

.close-icon-cross {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  right: 0.625rem;
  top: 0.625rem;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 0.5;
  cursor: pointer;
  z-index: $index-modal-close-icon;
  color: $surrogate-9;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(199, 18, 92, 0.05);
  }
}
