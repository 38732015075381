@import '../../styles/index';

.base {
  aspect-ratio: 16 / 9;
  border-radius: $border-radius-default;
  box-shadow: $shadow-hoverable;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
}

.activator {
  appearance: none;
  border: none;
  cursor: pointer;
  display: block;
  opacity: 1;
  padding: 0;
  position: relative;
  transition: opacity $duration-default;

  &::after {
    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3rem auto;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3EICONS/Solid/play%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='ICONS/Solid/play' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M28.4197,16.0006 C28.4197,16.9606 27.9407,17.8406 27.1297,18.3606 L11.3197,28.5616 C10.8597,28.8516 10.3297,29.0006 9.7997,29.0006 C9.3397,29.0006 8.8797,28.8916 8.4597,28.6606 C7.5607,28.1706 6.9997,27.2306 6.9997,26.2006 L6.9997,5.8006 C6.9997,4.7806 7.5607,3.8406 8.4597,3.3406 C9.3597,2.8516 10.4597,2.8916 11.3197,3.4506 L27.1297,13.6506 C27.9407,14.1706 28.4197,15.0506 28.4197,16.0006' id='Icon-Color' fill='%23fff'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    border-radius: $border-radius-default;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform $duration-default;
  }

  &:hover {
    &::after {
      transform: scale(1.2);
    }
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.activator {
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.player {
  height: 100%;
  width: 100%;
  left: 0;
  position: relative;
  top: 0;
}

.thumbnail {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
