.icon {
  fill: currentColor;
  display: inline-block;
}

img.icon {
  height: 1rem;
}

.icon-size16 {
  width: 1rem;
  height: 1rem;
}

.icon-size18 {
  width: 1.125rem;
  height: 1.125rem;
}

.icon-size20 {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-size24 {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-size32 {
  width: 2rem;
  height: 2rem;
}

.icon-size40 {
  width: 2.5rem;
  height: 2.5rem;
}

.icon-size48 {
  width: 3rem;
  height: 3rem;
}

.icon-size64 {
  width: 4rem;
  height: 4rem;
}

.icon-size128 {
  width: 8rem;
  height: 8rem;
}

.icon > svg {
  display: block;
  height: 100%;
  width: 100%;
}
