@import '../../styles/index';

.base {
  width: calc(100% + 2rem);
  position: relative;
  overflow-x: scroll;
  display: flex;
  margin-left: -1rem;
  margin-right: -2rem;

  &:hover {
    cursor: grab;
  }
}

.track {
  //width: 9999px;
  transition: transform 500ms;
  //margin-left: -1rem;
  display: flex;
  //justify-content: space-between;
}

.child {
  padding: 1rem;
  width: 100%;
  min-width: 300px;
}
