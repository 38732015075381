@import '../functions/media-queries';

$responsive-breakpoints: (
  'xxs',
  'xsbetween',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl'
);

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin responsive-properties($for) {
  @each $property in $for {
    #{$property}: var(--#{$property}-initial);
  }

  $fallback: var(--PROPERTY-initial);

  @each $breakpoint in $responsive-breakpoints {
    $fallback: var(--PROPERTY-#{$breakpoint}, $fallback);

    @include media-from($breakpoint) {
      @each $property in $for {
        &[data-responsive-breakpoints~='#{$property}:#{$breakpoint}'] {
          #{$property}: str-replace($fallback, PROPERTY, $property);
        }
      }
    }
  }
}
