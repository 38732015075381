@import '../../styles/index';

.wrapper {
  overflow: hidden;
  position: relative;
}

.img-base {
  display: block;
  max-width: 100%;
  opacity: 0;
  position: relative;
  transition: opacity $duration-default ease $duration-default;
}

.fit-cover {
  height: 100%;
  max-width: initial;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}

.visible {
  opacity: 1;
}

.with-shadow {
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.12);
}

.scale-to-fit {
  width: 100%;
}
