@import '../../styles/index';

.modal {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  overflow-y: scroll;
  min-width: 100vw;

  > div {
    max-width: 100%;

    @include media-from(lg) {
      max-width: calc(1024px + 4rem);
    }
  }
}

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
}

.backdrop {
  position: fixed;
  pointer-events: none;
  margin-top: -100vh;
  z-index: $index-negative;
  display: block;
  height: 300vh;
  width: 100%;
  left: 0;
}

.gradient {
  background: $gradient-enercity;
}

.shade {
  background: rgba(0, 2, 16, 0.68);
}

.close-icon {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  color: $surrogate-9;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $neutral-0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  z-index: 20;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 8px 32px 0 rgba(0, 0, 0, 0.16);

  position: absolute;
  right: 1rem;
  top: 1rem;

  @include media-from(lg) {
    right: 2rem;
  }
}

.close-icon-click-surface {
  position: absolute;
  height: 100%;
  width: 100%;
}

.close-icon-cross {
  color: $surrogate-9;
}

.close-button-content-box-styles {
  .close-icon {
    display: none;
  }

  .close-icon-cross {
    position: absolute;
    z-index: 100;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: all;
    cursor: pointer;

    top: 1.125rem;
    right: 2rem;

    @include media-from(xs) {
      top: 1.75rem;
      right: 4.25rem;
    }

    @include media-from(md) {
      top: 1.75rem;
      right: 4rem;
    }
  }
}

.layers {
  position: relative;
}

.content-center {
  display: flex;
  align-items: center;
}

.layer-child {
  max-width: 100%;

  @include media-to(sm) {
    height: 100%;
    margin-top: 2rem;
  }

  @include vertical-media-to(big) {
    height: 100%;
    margin-top: 2rem;
  }

  @include media-from(lg) {
    max-width: calc(1024px + 4rem);
  }
}
